<template>
    <TsPanelCustomScroll :loading="loading">
        <ts-page-title
            :title="$t('agencyProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('agencyProfile.pageTitle'),
                    href: 'admin/agency/profiles'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <div class="row">
            <div class="col-md-4 tw-space-y-4">
                <!-- basic info -->
                <BasicInfo v-model="model" :validate="errors" />
                <div
                    class="row tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-0 md:tw-space-y-0"
                >
                    <div class="col-md-6">
                        <ts-panel>
                            <ts-panel-wrapper>
                                <label class="tw-mb-2">{{
                                    $t("agencyProfile.idfront")
                                }}</label>
                                <UploadImageFront
                                    v-model="model.id_back"
                                    ref="idfront"
                                    @change="file => (idPhotos.front = file)"
                                />
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                    <div class="col-md-6">
                        <ts-panel>
                            <ts-panel-wrapper>
                                <label class="tw-mb-2">{{
                                    $t("agencyProfile.idback")
                                }}</label>
                                <UploadImageBack
                                    v-model="model.id_back"
                                    ref="idback"
                                    @change="file => (idPhotos.back = file)"
                                />
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
            </div>
            <div class="col-md-8 tw-space-y-4">
                <ts-panel>
                    <ts-panel-wrapper>
                        <h5>{{ $t("agencyProfile.address") }}</h5>
                        <div class="row">
                            <div class="col-md-4">
                                <AddressAgency
                                    v-model="model"
                                    :validate="errors"
                                    @loading="v => (loading = v)"
                                    ref="addressagency"
                                />
                            </div>
                            <div class="col-md-8">
                                <!-- geolocation -->
                                <GeoLocation
                                    v-model="model"
                                    ref="goelocation"
                                />
                            </div>
                        </div>
                    </ts-panel-wrapper>
                </ts-panel>
                <!-- bank account -->
                <BankAccount v-model="model" :validate="errors" />
            </div>
        </div>
        <template v-slot:footer>
            <ts-button
                @click.prevent="$router.push({ name: 'agency-profile' })"
                :disabled="loading"
                >{{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                @click.prevent="onUpdate"
                :disabled="loading"
                >{{ $t("update") }}</ts-button
            >
        </template>
    </TsPanelCustomScroll>
</template>

<script>
import { Errors } from "form-backend-validation";
import UploadImageFront from "./components/upload-image-front.vue";
import UploadImageBack from "./components/upload-image-back.vue";
import GeoLocation from "./components/geolocaiton.vue";
import BankAccount from "./components/bank-account.vue";
import AddressAgency from "./components/address.vue";
import BasicInfo from "./components/basic-info.vue";
import { mapActions, mapState } from "vuex";

export default {
    name: "agencyEdit",
    components: {
        UploadImageFront,
        UploadImageBack,
        GeoLocation,
        BankAccount,
        AddressAgency,
        BasicInfo
    },
    data() {
        return {
            loading: false,
            errors: new Errors(),
            idPhotos: {
                front: "",
                back: ""
            },
            model: {
                agency_id: "",
                agency_code: "",
                agency_name: "",
                contact_person: "",
                phone1: "",
                phone2: "",
                email: "",
                province_code: "",
                district_code: "",
                commune_code: "",
                village_code: "",
                contract_start_date: "",
                contract_end_date: "",
                id_front: "",
                id_back: "",
                payment_info: [],
                geo_location: {
                    lat: "",
                    lng: ""
                },
                is_company_agency: false,
                is_active: false,
                user_name: "",
                password: null,
                password_confirmation: null
            }
        };
    },
    computed: {
        ...mapState("agency/agencyProfile", ["edit_data"])
    },
    methods: {
        ...mapActions("agency/agencyProfile", ["find"]),
        onUpdate() {
            this.errors = new Errors();
            this.loading = true;
            this.$store
                .dispatch("agency/agencyProfile/update", {
                    id: this.model.agency_id,
                    data: this.model
                })
                .then(async response => {
                    await this.uploadLogo(this.model.agency_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$router.push({ name: "agency-profile" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async uploadLogo(agencyId) {
            if (
                (this.idPhotos.back instanceof File ||
                    this.idPhotos.front instanceof File) | Blob
            ) {
                let formData = new FormData();
                formData.append("back", this.idPhotos.back);
                formData.append("front", this.idPhotos.front);
                formData.append("agency_id", agencyId);
                let response = await this.$store.dispatch(
                    "agency/agencyProfile/upload",
                    formData
                );
                return response;
            }
            return "";
        },
        async setEdit() {
            await this.find(this.$route.params.id);
            await this.$refs.addressagency.fetchFormView(false, {
                params: {
                    provinceCode: this.edit_data.province_code,
                    districtCode: this.edit_data.district_code,
                    communeCode: this.edit_data.commune_code
                }
            });
            this.model.agency_id = this.edit_data.agency_id;
            this.model.agency_code = this.edit_data.agency_code;
            this.model.agency_name = this.edit_data.agency_name;
            this.model.contact_person = this.edit_data.contact_person;
            this.model.phone1 = this.edit_data.phone1;
            this.model.phone2 = this.edit_data.phone2;
            this.model.email = this.edit_data.email;
            this.model.province_code = this.edit_data.province_code;
            this.model.district_code = this.edit_data.district_code;
            this.model.commune_code = this.edit_data.commune_code;
            this.model.village_code = this.edit_data.village_code;
            this.model.contract_start_date = this.edit_data.contract_start_date;
            this.model.contract_end_date = this.edit_data.contract_end_date;
            this.model.id_front = this.edit_data.id_front;
            this.model.id_back = this.edit_data.id_back;
            this.model.payment_info = this.edit_data.payment_info;
            this.model.is_company_agency = this.edit_data.is_company_agency;
            this.model.is_active = this.edit_data.is_active;

            if (this.edit_data.geo_location) {
                this.model.geo_location.lat = this.edit_data.geo_location[0].lat;
                this.model.geo_location.lng = this.edit_data.geo_location[0].lng;
                this.$refs.goelocation.center = this.model.geo_location;
                this.$refs.goelocation.marker = this.model.geo_location;
                this.$refs.goelocation.zoom = 18;
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.setEdit();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/agencyProfile/SET_EDIT_DATA", []);
        next();
    }
};
</script>
