var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TsPanelCustomScroll',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('ts-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'agency-profile' })}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('ts-button',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("update")))])]},proxy:true}])},[_c('ts-page-title',{attrs:{"title":_vm.$t('agencyProfile.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('agencyProfile.pageTitle'),
                href: 'admin/agency/profiles'
            },
            {
                text: _vm.$t('create'),
                active: true
            }
        ]}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 tw-space-y-4"},[_c('BasicInfo',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('div',{staticClass:"row tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-0 md:tw-space-y-0"},[_c('div',{staticClass:"col-md-6"},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('label',{staticClass:"tw-mb-2"},[_vm._v(_vm._s(_vm.$t("agencyProfile.idfront")))]),_c('UploadImageFront',{ref:"idfront",on:{"change":function (file) { return (_vm.idPhotos.front = file); }},model:{value:(_vm.model.id_back),callback:function ($$v) {_vm.$set(_vm.model, "id_back", $$v)},expression:"model.id_back"}})],1)],1)],1),_c('div',{staticClass:"col-md-6"},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('label',{staticClass:"tw-mb-2"},[_vm._v(_vm._s(_vm.$t("agencyProfile.idback")))]),_c('UploadImageBack',{ref:"idback",on:{"change":function (file) { return (_vm.idPhotos.back = file); }},model:{value:(_vm.model.id_back),callback:function ($$v) {_vm.$set(_vm.model, "id_back", $$v)},expression:"model.id_back"}})],1)],1)],1)])],1),_c('div',{staticClass:"col-md-8 tw-space-y-4"},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('h5',[_vm._v(_vm._s(_vm.$t("agencyProfile.address")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('AddressAgency',{ref:"addressagency",attrs:{"validate":_vm.errors},on:{"loading":function (v) { return (_vm.loading = v); }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('div',{staticClass:"col-md-8"},[_c('GeoLocation',{ref:"goelocation",model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])])],1),_c('BankAccount',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }